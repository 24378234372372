<script setup>

</script>

<template>
  <div class="pageNotFoundWrapper">
    <h2>Podana strona nie istnieje</h2>
    <router-link to="/">Powrót na stronę główną</router-link>
  </div>
</template>

<style scoped lang="scss">
  @import '../assets/styles/colors';

  div.pageNotFoundWrapper {
    min-height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $color-light-green;

    & > a {
      color: $color-black;
    }
  }

</style>