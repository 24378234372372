<script lang="ts" setup>

</script>

<template>
  <div class="introductionWrapper">
    <img src="../assets/icons/hearth-with-plus.svg" width="100" height="95" alt="Ikona serca z plusem w środku" />
    <h2>W trosce o Twoje zdrowie</h2>
    <p>Przy użyciu nowoczesnego sprzętu, z pełnym zaangażowaniem wykonuję badania,
       stawiam diagnozy i wprowadzam leczenie zapewniając profesjonalizm i należytą
       opiekę medyczną pacjentowi.</p>
    <router-link to="/about-me">Więcej</router-link>
  </div>
</template>

<style scoped lang="scss">
  @import '../assets/styles/colors';
  @import '../assets/styles/mixins';

  div.introductionWrapper {
    min-height: 550px;
    background-image: url("../assets/images/introduction-background.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 0.5rem;

    @include respond-to(min-width, 568px) {
      padding: 1rem 2rem;
      min-height: 768px;
    }

    & > img {
      max-width: 80px;
      max-height: 80px;
      object-fit: contain;
    }

    & > h2 {
      margin: 2rem 0 0 0;
      font-size: 2.5rem;
      font-weight: normal;
      color: $color-light-green;
      text-align: center;

      @include respond-to(min-width, 568px) {
        font-size: 4rem;
      }
    }

    & > p {
      font-size: 1rem;
      max-width: 850px;
      text-align: center;
      margin: 1rem;

      @include respond-to(min-width, 568px) {
        font-size: 1.25rem;
        margin: 2rem;
      }
    }

    & > a {
      padding: 0.5rem 2rem;
      background-color: $color-dark-green;
      color: $color-white;
      border-radius: 5px;
      border: none;
      cursor: pointer;
      font-size: 1rem;
      transition: 300ms ease-in-out;
      text-decoration: none;

      @include respond-to(min-width, 568px) {
        padding: 0.75rem 3rem;
        font-size: 1.25rem;
      }

      &:hover {
        box-shadow: 0 0 10px 6px $color-light-green;
        transition: 300ms ease-in-out;
      }
    }
  }

</style>