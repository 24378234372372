<script setup>

</script>

<template>
  <footer class="footerWrapper">
    <div class="mainInfo">
      <img src="../assets/icons/big-plus.svg" width="100" height="100" alt="Ikona plusa" />
      <h2>lek. Piotr Bereś</h2>
      <a href="https://maps.app.goo.gl/hG4rR2c4DqQHt7yV6" target="_blank">
        <p>ELMED Nagawczyna</p>
        <p>Nagawczyna 24A</p>
      </a>
    </div>
    <div class="registrationInformation">
      <div>
        <p>Rejestracja</p>
        <p>Odbywa się w dni powszednie</p>
        <p>W godz. 8:00 do 18:00</p>
      </div>
      <div>
        <h3>Telefonicznie:</h3>
        <a href="tel:146969988">14 696 99 88</a>
      </div>
      <div>
        <h3>W recepcji ELMED Nagawczyna</h3>
        <a href="https://maps.app.goo.gl/hG4rR2c4DqQHt7yV6" target="_blank">Nagawczyna 24A</a>
      </div>
    </div>
  </footer>
</template>

<style scoped lang="scss">
  @import '../assets/styles/colors';
  @import '../assets/styles/mixins';

  footer.footerWrapper {
    min-height: 300px;
    background-image: url("../assets/images/footer-background.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
    padding: 1rem 0.5rem;

    @include respond-to(min-width, 568px) {
      padding: 1rem 2rem;
    }

    @include respond-to(min-width, 768px) {
      min-height: 500px;
    }

    & > div {
      height: 100%;

      & > a {
        text-decoration: none;
      }

      &.mainInfo {
        display: grid;
        grid-template-rows: 1fr auto auto 1fr;

        & > h2 {
          font-size: 2r 1em;
          font-weight: normal;

          @include respond-to(min-widthm, 768px) {
            font-size: 2rem;
          }

          @include respond-to(min-width, 1024px) {
            font-size: 3rem;
          }
        }

        & > a {
          font-size: 1rem;
          color: $color-black;

          &:hover {
            text-decoration: underline;
            text-decoration-thickness: 2px;
          }

          @include respond-to(min-widthm, 768px) {
            font-size: 2rem;
          }

          @include respond-to(min-width, 1024px) {
            font-size: 3rem;
          }

          & > p {
            margin: 0;
          }
        }

        & > img {
          max-width: 60px;
          max-height: 60px;
          object-fit: contain;

          @include respond-to(min-width, 768px) {
            max-width: 80px;
            max-height: 80px;
          }
        }
      }

      &.registrationInformation {
        display: flex;
        flex-direction: column;
        justify-content: center;
        //margin-left: 3rem;
        font-size: 0.75rem;

        @include respond-to(min-width, 568px) {
          margin-left: 3rem;
        }

        @include respond-to(min-width, 768px) {
          font-size: 1rem;
        }

        & > div {
          margin: 0.5rem 0;

          @include respond-to(min-width, 768px) {
            margin: 1rem 0;
          }

          & > p {
            margin: 0;
            text-transform: uppercase;
            letter-spacing: 1px;

            @include respond-to(min-width, 768px) {
              letter-spacing: 2px;
            }
          }

          & > h3 {
            font-size: 1rem;
            margin-bottom: 0.5rem;
            letter-spacing: 1px;
            letter-spacing: 1px;

            @include respond-to(min-width, 768px) {
              font-size: 1.5rem;
              letter-spacing: 2px;
            }
          }

          & > a {
            text-decoration: none;
            color: $color-black;

            &:hover {
              text-decoration: underline;
              text-decoration-thickness: 2px;
            }
          }
        }
      }
    }
  }
</style>