<template>
  <navbar @scrollToFooter="scrollToFooter"></navbar>
  <main class="content">
    <router-view />
    <Footer ref="footerRef"></Footer>
  </main>
</template>

<script>
import Footer from "@/components/Footer.vue";
import Navbar from "@/components/Navbar.vue";
import {ref} from "vue";

export default {
  name: 'App',
  components: {
    Navbar,
    Footer
  },
  setup() {
    const footerRef = ref(null);

    const scrollToFooter = () => {
      if (footerRef.value) {
        footerRef.value.$el.scrollIntoView({behavior: 'smooth'});
      }
    }

    return {footerRef, scrollToFooter}
  }
}
</script>

<style lang="scss">
@import "./assets/styles/colors";
@import './assets/styles/general';
@import './assets/styles/reusable';

main.content {
  max-width: 1920px;
  margin: $navbarHeight auto 0 auto;
}
</style>
