<template>
  <div class="proctologyWrapper">
    <div>
      <img src="../assets/icons/proctology.svg" width="200" height="183" alt="Ikona proktologii" />
      <h2>Proktologia</h2>
    </div>

    <section class="diagnosisAndTreatmentWrapper" :class="{'active': diagnosisAndTreatmentSectionStatus}">
      <button @click="toggleSection('diagnosisAndTreatment')">
        <img src="../assets/icons/arrow.svg" width="100" height="87" alt="Ikona strzałki" />
        <div>Diagnostyka i leczenie chorób</div>
      </button>

      <div class="content">
        <ul>
          <div class="iconWrapper">
            <img class="plusIcon" src="../assets/icons/big-plus.svg" width="233" height="233" alt="Ikona plusa" />
          </div>

          <li>
            <h4>Guzki krwawnicze "hemoroidy"</h4>
            <ul>
              <li>Leczenie zachowawcze</li>
              <li>Skleroterapia</li>
              <li>Zabieg sposobem Barrona (opaskowanie/gumkowanie)</li>
            </ul>
          </li>
          <li>
            <h4>Szczelina odbytu</h4>
            <ul>
              <li>Leczenie zachowawcze</li>
              <li>Sfinkterotomia farmakologiczna (NTG, Diltiazem, Botox)</li>
            </ul>
          </li>

          <li>
            <h4>Zakrzep brzeżny</h4>
            <ul>
              <li>
                <strong>leczenie zachowawcze</strong>
              </li>
              <li>
                <strong>leczenie zabiegowe</strong>
              </li>
            </ul>
          </li>

          <li>
            <h4>Krwawienie z odbytu</h4>
          </li>

          <li>
            <h4>Popromienne zapalenie prostnicy</h4>
          </li>

          <li>
            <h4>Ropień odbytu</h4>
          </li>

          <li>
            <h4>Przetoki odbytu</h4>
          </li>

          <li>
            <h4>Rak odbytu</h4>
          </li>
        </ul>

        <ul>
          <div class="iconWrapper">
            <img class="plusIcon" src="../assets/icons/big-plus.svg" width="233" height="233" alt="Ikona plusa" />
          </div>

          <li>
            <h4>Choroby proktologiczne w ciąży</h4>
          </li>

          <li>
            <h4>Fałd brzeżny</h4>
          </li>

          <li>
            <h4>Kłykciny kolczyste</h4>
          </li>

          <li>
            <h4>Polipy odbytu i odbytnicy</h4>
          </li>

          <li>
            <h4>
              <div>Zaburzenia czynnościowe jelita grubego</div>
              <div>(biegunki, zaparcia)</div>
            </h4>
          </li>

          <li>
            <h4>Cysty pilonidalne (włosowe)</h4>
          </li>

          <li>
            <h4>
              <div>Trądzik odwrócony</div>
              <div>(acne inversa, zapalenie gruczołów apokrynowych)</div>
            </h4>
          </li>

          <li>
            <h4>Świąd i pieczenie odbytu</h4>
          </li>

          <li>
            <h4>Usuwanie ciał obcych z odbytu</h4>
          </li>

          <li>
            <h4>Drobne zabiegi chirurgiczne</h4>
          </li>

        </ul>
      </div>

      <button class="iconWrapper" @click="toggleSection('diagnosisAndTreatment')">
        <img class="plusIcon" src="../assets/icons/big-plus.svg" width="233" height="233" alt="Ikona plusa" />
      </button>
    </section>

    <hr/>

    <section class="firstVisitWrapper" :class="{'active': firstVisitSectionStatus}">
      <button @click="toggleSection('firstVisit')">
        <img src="../assets/icons/arrow.svg" width="100" height="87" alt="Ikona strzałki" />
        <div>Jak przygotować się do pierwszej wizyty proktologicznej?</div>
      </button>

      <div>
        <p>
          Proktologia jest dziedziną medycyny, skupiającą się na diagnozowaniu i leczeniu schorzeń
          końcowego odcinka układu pokarmowego, czyli odbytu, kanału odbytu, odbytnicy oraz
          jelita grubego. Wizyta u proktologa nie wymaga specjalnych przygotowań. Przed wizytą w
          gabinecie dobrze jest zadbać o wypróżnienie się oraz o umycie okolicy odbytu.
        </p>

        <h4>
          Konsultacja proktologiczna składa się z kilku etapów:
        </h4>

        <ul>
          <li>
            Pierwszy to szczegółowy wywiad z pacjentem. Proktolog pyta o przebyte choroby,
            stosowane leki oraz dolegliwości. Ważne są też informacje na temat stosowane diety
            czy aktywności fizycznej.
          </li>
          <li>
            Kolejny etap wizyty to podstawowe badanie per rectum. Proktolog zbada palcem
            kondycję odbytu, odbytnicy i kanału odbytu.
          </li>
          <li>
            Następnie lekarz wykonuje szczegółowe badanie specjalistyczne czyli anoskopię lub
            rektoskopię. Mają one na celu wykrycie nieprawidłowości w obrębie kanału odbytu.
          </li>
          <li>
            Badania te wykonuje się również wtedy, kiedy leczenie farmakologiczne nie przynosi
            efektów. W takim przypadku możliwe, że konieczny będzie zabieg chirurgiczny.
          </li>
        </ul>

        <strong>
          Ważne! Wielu Pacjentom podczas pierwszej wizyty towarzyszy poczucie dyskomfortu i
          wstyd, jednak mimo to nie należy odwlekać jej w czasie, gdyż dolegliwości, których
          przyczyną są zmiany chorobowe końcowego odcinka układu pokarmowego, mają
          charakter nawracający lub przewlekły i sprawiają chorym ból.
        </strong>
      </div>

      <button class="iconWrapper" @click="toggleSection('firstVisit')">
        <img class="plusIcon" src="../assets/icons/big-plus.svg" width="233" height="233" alt="Ikona plusa" />
      </button>
    </section>

    <hr />

    <section class="transrectalWrapper" :class="{'active': transrectalStatus}">
      <button @click="toggleSection('transrectal')">
        <img src="../assets/icons/arrow.svg" width="100" height="87" alt="Ikona strzałki" />
        <div>USG transrektalne - niezwykle precyzyjne badanie</div>
      </button>

      <div>
        <p>
          <strong>
            U mnie w gabinecie ultrasonograficzna ocena kanału odbytu i odbytnicy
            prowadzona jest przy użyciu rotacyjnej głowicy 3D o wysokiej częstotliwości,
            którą posiadają tylko nieliczne placówki w Polsce.
          </strong>
          Zapewnia to możliwość oceny
          całego obwodu oglądanego odbytu lub odbytnicy. Rotacyjna, trójwymiarowa
          głowica posiada wirujący kryształ i daje obraz 360 stopni, w przeciwieństwie do
          innych głowic umożliwiających zobrazowanie tylko wycinka oglądanego fragmentu
          odbytu. Uzyskanie obrazu całego obwodu odbytu i odbytnicy daje możliwość
          porównania ściany jelita, a co za tym idzie pozwala na dokładne określenie
          odnalezionych zmian patologicznych.
        </p>
        <p>
          Głowica rotacyjna 360 stopni posiada opcję obrazowania trójwymiarowego (3D),
          która daje możliwość dokładnej analizy uzyskanych obrazów i oglądania zmian z każdej
          strony.
        </p>
        <p>
          Uzyskiwane w ten sposób obrazy są tak precyzyjne, że umożliwiają dokładną ocenę
          zmiany nowotworowej ? głębokość naciekania na ściany jelita, naciekanie okolicznych
          tkanek i ewentualnie. zmiany w okolicznych węzłach chłonnych. Wynik tego badania jest
          decydujący o wyborze metody operacji nowotworu odbytu i odbytnicy ( miejscowe
          resekcje, resekcja lub amputacja odbytnicy). Badanie wykorzystywane jest także w
          monitoringu leczenia choroby nowotworowej i odpowiedzi guza na chemio- i
          radioterapię.
        </p>

        <div class="images">
          <img class="firstDevice" src="../assets/images/device_1.webp" width="480" height="1000" alt="Urządzenie do USG" />
          <img class="secondDevice" src="../assets/images/device_2.webp" width="480" height="480" alt="Rotacyjna głowica 3D" />
        </div>

        <p>
          Innym zastosowaniem rotacyjnej trójwymiarowej głowicy w badaniach
          proktologicznych może być: ocena zwieraczy kanału odbytu ( stan po urazach często
          poporodowych lub po operacjach proktologicznych dających problemy z prawidłowym
          funkcjonowaniem zwieraczy). Badanie takie pozwala odpowiedzieć na pytanie, czy
          istnieje możliwość operacji naprawczych, wskazując na miejsce uszkodzenia mięśni i ich
          wspólnie działanie.
        </p>

        <p>
          Ważnym zastosowaniem tej metody, jako uzupełniającej badanie proktologiczne, jest
          badanie ropni i przetok okołoodbytniczych. Badanie umożliwia odnalezienie niewielkiego ropienia
          odbytu i odbytnicy, który może być odpowiedzialny za niczym nieusprawiedliwioną
          gorączkę, ból w okolicy odbytu. Pozwala też na skontrolowanie prawidłowości
          zdrenowania ropnia okołoodbytniczego.
        </p>

        <p>
          Często naturalnym zejściem ropnia okołoodbytniczego jest przetoka okołoodbytnicza,
          dająca ból, pieczenie w okolicy odbytu i wyciek treści ropnej. Badanie rotacyjną,
          trójwymiarową głowicą całego odbytu potrafi dokładnie określić przebieg kanału
          przetoki, odnaleźć ujście wewnętrzne – co jest warunkiem radykalnego leczenia
          operacyjnego. Określając przebieg przetoki w stosunku do zwieraczy odbytu uzyskuje się
          możliwość wybrania jak najbardziej oszczędnego dla zwieraczy zakresu i metody
          operacji. Czasami w celu lepszego uwidocznienia przebiegu kanału odbytu do ujścia
          zewnętrznego podaje się niewielką ilość wody utlenionej.
        </p>

        <p>
          Badanie USG głowicą rotacyjną 3D jest bardzo ważnym uzupełnieniem pełnego badania
          proktologicznego. W niektórych przypadkach badaniem rozstrzygającym o
          możliwości i zakresie leczenia operacyjnego.
        </p>
      </div>

      <button class="iconWrapper" @click="toggleSection('transrectal')">
        <img class="plusIcon" src="../assets/icons/big-plus.svg" width="233" height="233" alt="Ikona plusa" />
      </button>
    </section>

    <hr />

    <section class="priceListWrapper" :class="{'active': priceListSectionStatus}">
      <button @click="toggleSection('priceList')">
        <img src="../assets/icons/arrow.svg" width="100" height="87" alt="Ikona strzałki" />
        <div>Cennik</div>
      </button>

      <div>
        <ul>
          <li>
            <span class="name">
              <strong>Konsultacja proktologiczna z badaniem wziernikowym oraz USG transrektalnym 3D</strong>
            </span>
            <span>400zł</span>
          </li>
          <li>
            <span class="name">
              <strong>Konsultacja proktologiczna z anoskopią</strong>
            </span>
            <span>150zł</span>
          </li>
          <li>
            <span class="name">
              <strong>Konsultacja proktologiczna z rektoskopią</strong>
            </span>
            <span>220zł</span>
          </li>
          <li>
            <span class="name">
              <strong>Zabieg sp. barrona (gumkowanie) - I sesja</strong>
            </span>
            <span>250zł</span>
          </li>
          <li>
            <span class="name">
              <strong>Nacięcie i drenaż ropnia w znieczuleniu miejscowym</strong>
            </span>
            <span>350zł</span>
          </li>
          <li>
            <span class="name">
              <strong>Nacięcie i ewakułacja zakrzepu brzeżnego w znieczuleniu miejscowym</strong>
            </span>
            <span>300zł</span>
          </li>
          <li>
            <span class="name">
              <strong>Usunięcie ciała obcego z odbytu</strong>
            </span>
            <span>250zł</span>
          </li>
          <li>
            <span class="name">
              <strong>Wycięcie niskiej przetoki odbytu w znieczuleniu miejscowym</strong>
            </span>
            <span>300zł</span>
          </li>
          <li>
            <span class="name">
              <strong>Skleroterapia</strong>
              <small>(cena zawiera podanie oraz koszt preparatu)</small>
            </span>
            <span>600zł</span>
          </li>
          <li>
            <span class="name">
              <strong>Podanie toksyny botulinowej w szczelinie odbytu</strong>
              <small>(+ koszt preparatu - pacjent kupuje samodzielnie)</small>
            </span>
            <span>250zł</span>
          </li>
        </ul>

        <div class="additionalInformation">Małe zabiegi chirurgiczne według indywidualnej wyceny w zależności od wielkości oraz lokalizacji zmiany</div>
        <br/>
        <div class="additionalInformation">Możliwość płatności kartą</div>
      </div>

      <button class="iconWrapper" @click="toggleSection('priceList')">
        <img class="plusIcon" src="../assets/icons/big-plus.svg" width="233" height="233" alt="Ikona plusa" />
      </button>
    </section>
  </div>
</template>

<style scoped lang="scss">
  @import '../assets/styles/colors';
  @import '../assets/styles/reusable';
  @import '../assets/styles/mixins';

  div.proctologyWrapper {
    @extend %diagnostics;

    & > section {
      &.diagnosisAndTreatmentWrapper {
        & > div.content {
          display: grid;
          grid-template-columns: 100%;

          @include respond-to(min-width, 768px) {
            grid-template-columns: calc(50% - 1rem) calc(50% - 1rem);
            grid-gap: 2rem;
          }

          & > ul {
            padding-left: 1rem;
            margin-top: 0;

            @include respond-to(min-width, 768px) {
              padding-left: 2rem;
            }

            & > li {
              margin: 1.5rem 0;
              list-style-type: none;

              &:last-child {
                margin-bottom: 0;
              }

              & > h4 {
                font-size: 1.25rem;
                margin: 0.25rem 0;
              }

              & > ul {
                & > li {
                  list-style-type: disc;
                  margin: 0.5rem 0;
                }
              }
            }
          }
        }
      }

      &.firstVisitWrapper {
        & > div {
          padding: 0 1rem;

          @include respond-to(min-width, 768px) {
            padding: 0 2rem;
          }

          & > h4 {
            font-size: 1.25rem;
          }

          & > p,
          & > h3,
          & > h4,
          & > strong,
          & > ul > li {
            margin: 0.75rem 0;

            @include respond-to(min-width, 768px) {
              margin: 1.5rem 0;
            }
          }

          & > strong {
            display: block;
          }
        }
      }

      &.transrectalWrapper {
        display: grid;

        & > div {
          & > div.images {
            display: grid;
            grid-template-columns: 1fr;
            justify-items: center;
            align-items: center;

            @include respond-to(min-width, 768px) {
              grid-template-columns: 1fr 1fr;
            }

            & > img {
              width: auto;
              object-fit: contain;

              &.firstDevice {
                max-height: 480px;
              }

              &.secondDevice {
                max-height: 250px;
              }
            }
          }
        }
      }
    }
  }
</style>

<script>
import {ref} from "vue";

export default {
  name: 'Proctology',
  setup() {
    const diagnosisAndTreatmentSectionStatus = ref(true);
    const firstVisitSectionStatus = ref(false);
    const transrectalStatus = ref(false);
    const priceListSectionStatus = ref(false);
    const toggleSection = (section) => {
      switch(section) {
        case 'diagnosisAndTreatment':
          diagnosisAndTreatmentSectionStatus.value = !diagnosisAndTreatmentSectionStatus.value;
          break;
        case 'firstVisit':
          firstVisitSectionStatus.value = !firstVisitSectionStatus.value;
          break;
        case 'transrectal':
          transrectalStatus.value = !transrectalStatus.value;
          break;
        case 'priceList':
          priceListSectionStatus.value = !priceListSectionStatus.value;
          break;
      }
    }

    return {diagnosisAndTreatmentSectionStatus, firstVisitSectionStatus, transrectalStatus, priceListSectionStatus, toggleSection}
  }
}
</script>
