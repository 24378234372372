<template>
  <div class="woundTreatmentWrapper">
    <div>
      <img src="../assets/icons/wound-treatment.svg" width="200" height="200" alt="Ikona leczenia ran" />
      <h2>Leczenie ran</h2>
    </div>

    <section class="diagnosisWrapper" :class="{'active': diagnosisStatus}">
      <button @click="toggleSection('diagnosis')">
        <img src="../assets/icons/arrow.svg" width="100" height="87" alt="Ikona strzałki" />
        <div>Skuteczne leczenia ran ostrych i przewlekłych</div>
      </button>

      <div>
        <ul>
          <li>
            <span>Oparzenia</span>
          </li>
          <li>
            <span>Odleżyny</span>
          </li>
          <li>
            <span>Rany pooperacyjne</span>
          </li>
          <li>
            <span>W przebiegu stopy cukrzycowej</span>
          </li>
          <li>
            <span>W przebiegu owrzodzeń podudzi</span>
          </li>
          <li>
            <span>Chirurgiczne opracowywanie ran</span>
          </li>
          <li>
            <span>Chirurgiczne szycie ran</span>
          </li>
          <li>
            <span>Opatrunki specjalistyczne</span>
          </li>
        </ul>

      </div>

      <button class="iconWrapper" @click="toggleSection('diagnosis')">
        <img class="plusIcon" src="../assets/icons/big-plus.svg" width="233" height="233" alt="Ikona plusa" />
      </button>
    </section>

    <hr />


    <section class="priceListWrapper" :class="{'active': priceListSectionStatus}">
      <button @click="toggleSection('priceList')">
        <img src="../assets/icons/arrow.svg" width="100" height="87" alt="Ikona strzałki" />
        <div>Cennik</div>
      </button>

      <div>
        <ul>
          <li>
            <span class="name">
              <strong>Opracowanie i zszycie rany ostrej w znieczuleniu miejscowym</strong>
            </span>
            <span>250zł</span>
          </li>
          <li>
            <span class="name">
              <strong>Pierwsza wizyta</strong>
              <small>(obejmuje rozpoznanie problemu, opracowanie-oczyszczenie rany, dobór i założenie opatrunku, ustalenie indywidualnego planu leczenia rany)</small>
            </span>
            <span>220zł</span>
          </li>
          <li>
            <span class="name">
              <strong>Kolejna wizyta</strong>
              <small>(ocena skuteczności leczenia, zmiana opatrunku, opracowanie rany, dalsze zalecenia jak postępować z raną)</small>
            </span>
            <span>150zł</span>
          </li>
          <li>
            <span class="name">
              <strong>Kompresjoterapia</strong>
              <small>(usługa dodatkowo płatna)</small>
            </span>
            <span>110zł</span>
          </li>
        </ul>
        <div class="additionalInformation">Możliwość płatności kartą</div>

      </div>

      <button class="iconWrapper" @click="toggleSection('priceList')">
        <img class="plusIcon" src="../assets/icons/big-plus.svg" width="233" height="233" alt="Ikona plusa" />
      </button>
    </section>
  </div>
</template>

<style scoped lang="scss">
  @import '../assets/styles/colors';
  @import '../assets/styles/reusable';

  div.woundTreatmentWrapper {
    @extend %diagnostics;

    & > section {
      &.diagnosisWrapper {
        & > div {
          & > ul {
            padding-left: 1rem;

            @include respond-to(min-width, 768px) {
              padding-left: 2.5rem;
            }

            & > li {
              margin: 1.5rem 0;

              &:first-child {
                margin-top: 0;
              }

              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
      }

      &.homeVisitsWrapper {
        & > div {
          padding-left: 1rem;

          @include respond-to(min-width, 768px) {
            padding-left: 2.5rem;
          }
        }
      }
    }
  }
</style>

<script>
import {ref} from "vue";

export default {
  name: "WoundTreatment",
  setup() {
    const diagnosisStatus = ref(true);
    const homeVisitsStatus = ref(false);
    const priceListSectionStatus = ref(false);
    const toggleSection = (section) => {
      switch(section) {
        case 'diagnosis':
          diagnosisStatus.value = !diagnosisStatus.value;
          break;
        case 'homeVisits':
          homeVisitsStatus.value = !homeVisitsStatus.value;
          break;
        case 'priceList':
          priceListSectionStatus.value = !priceListSectionStatus.value;
          break;
      }
    }

    return {diagnosisStatus, homeVisitsStatus, priceListSectionStatus, toggleSection}
  }
}
</script>