<script setup>

</script>

<template>
  <div class="diagnosticsWrapper">
    <h2>Profesjonalna diagnostyka i leczenie chorób</h2>
    <div>
      <router-link to="/proctology">
        <img src="../assets/icons/proctology.svg" width="200" height="183" alt="Ikona proktologii" />
        <strong>Proktologia</strong>
        <img class="plus" src="../assets/icons/small-plus.svg" width="233" height="233" alt="Ikona plusa">
      </router-link>
      <router-link to="/ultrasound">
        <img src="../assets/icons/ultrasound.svg" width="200" height="194" alt="Ikona USG" />
        <strong>USG</strong>
        <img class="plus" src="../assets/icons/small-plus.svg" width="233" height="233" alt="Ikona plusa">
      </router-link>
      <router-link to="/wound-treatment">
        <img src="../assets/icons/wound-treatment.svg" width="200" height="200" alt="Ikona leczenia ran" />
        <strong>Leczenie ran</strong>
        <img class="plus" src="../assets/icons/small-plus.svg" width="233" height="233" alt="Ikona plusa">
      </router-link>
      <router-link to="/surgery">
        <img src="../assets/icons/surgery.svg" width="200" height="200" alt="Ikona leczenia ran" />
        <strong>Chirurgia</strong>
        <img class="plus" src="../assets/icons/small-plus.svg" width="233" height="233" alt="Ikona plusa">
      </router-link>
    </div>
  </div>
</template>

<style scoped lang="scss">
  @import '../assets/styles/colors';
  @import '../assets/styles/mixins';

  div.diagnosticsWrapper {
    --tile-width: 150px;
    --tile-height: var(--tile-width);

    background-color: $color-light-green;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 550px;
    padding: 1rem 0.5rem;

    @include respond-to(min-width, 568px) {
      --tile-width: 150px;
      --tile-height: var(--tile-width);
      padding: 1rem 2rem;
    }

    @include respond-to(min-width, 1366px) {
      --tile-width: 200px;
      --tile-height: var(--tile-width);
      min-height: 768px;
    }

    @include respond-to(min-width, 1600px) {
      --tile-width: 250px;
      --tile-height: var(--tile-width);
    }

    & > h2 {
      margin: 3rem 0;
      letter-spacing: 1px;
      font-size: 1.5rem;
      text-align: center;

      @include respond-to(min-width, 568px) {
        font-size: 2rem;
        margin: 5rem;
      }

      @include respond-to(min-width, 768px) {
        font-size: 2.5rem;
      }

      @include respond-to(min-width, 1024px) {
        font-size: 3rem;
      }
    }

    & > div {
      display: grid;
      grid-template-columns: repeat(1, 1fr);

      @include respond-to(min-width, 568px) {
        grid-template-columns: repeat(2, 1fr);
      }

      @include respond-to(min-width, 1024px) {
        grid-template-columns: repeat(4, 1fr);
      }

      & > a {
        width: var(--tile-width);
        height: var(--tile-height);
        background-color: $color-smoke;
        padding: 1.5rem;
        margin: 1rem;
        display: grid;
        grid-template-rows: calc(100% - 25px - 35px - 1.5rem) calc(25px - 0.5rem) calc(35px - 0.5rem);
        grid-gap: 1.5em;
        justify-content: center;
        align-content: center;
        border-radius: 30px;
        transition: 300ms ease-in-out;
        text-decoration: none;
        color: $color-black;

        @include respond-to(min-width, 568px) {
          margin: 2rem;
        }

        @include respond-to(min-width, 1024px) {
          grid-template-rows: calc(100% - 30px - 35px - 1.5rem) calc(30px - 0.5rem) calc(35px - 0.5rem);
        }

        &:hover {
          box-shadow: 0 0 10px 6px $color-dark-green;
          transition: 300ms ease-in-out;
        }

        & > img {
          width: auto;
          height: 100%;
          object-fit: contain;
          margin: auto;

          &.plus {
            width: 25px;
            height: 25px;
          }
        }

        & > strong {
          text-align: center;

          @include respond-to(min-width, 1366px) {
            font-size: 1.25rem;
          }
        }
      }
    }
  }

</style>