<template>
  <nav :class="{'active': scrollPositionY > 0, 'expand': hamburgerMenuStatus}">
    <h1>
      <router-link to="/">
        <img src="../assets/icons/plus.svg" width="100" height="100" alt="logo" />
      </router-link>
      <button class="hamburgerBtn" @click="openMenu">
        <img src="../assets/icons/bars.svg" width="14" height="16" alt="hamburger menu" />
      </button>
    </h1>
    <div class="links">
      <router-link to="/about-me">O mnie</router-link>
      <router-link to="/proctology">Proktologia</router-link>
      <router-link to="/ultrasound">Ultrasonografia</router-link>
      <router-link to="/wound-treatment">Leczenie ran</router-link>
      <router-link to="/surgery">Chirurgia</router-link>
      <a href="/contact" @click.prevent="scrollToFooter">Kontakt</a>
    </div>
  </nav>
</template>

<style scoped lang="scss">
  @import '../assets/styles/colors';
  @import '../assets/styles/reusable';
  @import '../assets/styles/mixins';

  nav {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 0.5rem;
    width: 100vw;
    height: $navbarHeight;
    position: fixed;
    top: 0;
    background-color: $color-light-green;
    box-sizing: border-box;
    z-index: 1;
    transition: box-shadow 300ms ease-in-out;
    border-bottom: 1px solid $color-white;

    @include respond-to(min-width, 768px) {
      flex-direction: row;
      padding: 0 1rem;
      align-items: center;
      border-bottom: none;
    }

    &.active {
      @include respond-to(min-width, 768px) {
        box-shadow: 0 0 10px 0 rgba($color-black, 0.75);
        transition: box-shadow 300ms ease-in-out;
      }
    }

    &.expand {
      & > div.links {
        transform: translateX(0);
        transition: transform 300ms ease-in-out;
      }
    }

    & > h1 {
      height: 100%;
      margin: 0;
      display: flex;
      justify-content: space-between;

      & > a {
        height: 100%;
        display: flex;
        align-items: center;

        @include respond-to(min-width, 768px) {
          justify-content: center;
        }

        & > img {
          --image-width: 35px;
          --image-height: var(--image-width);
          width: var(--image-width);
          height: var(--image-height);

          @include respond-to(min-width, 414px) {
            --image-width: 40px;
          }

          @include respond-to(min-width, 768px) {
            --image-width: 45px;
          }
        }
      }

      & > button.hamburgerBtn {
        background-color: transparent;
        border: none;
        cursor: pointer;

        @include respond-to(min-width, 768px) {
          display: none;
        }

        & > img {
          width: 25px;
          height: 25px;
        }
      }
    }

    & > div.links {
      width: 100%;
      display: flex;
      flex-direction: column;
      position: absolute;
      top: $navbarHeight;
      left: 0;
      background-color: $color-light-green;
      box-shadow: 0 5px 10px -5px rgba($color-black, 0.75);
      transform: translateX(-100%);
      transition: box-shadow 300ms ease-in-out,
                  transform 300ms ease-in-out;


      @include respond-to(min-width, 768px) {
        width: auto;
        position: static;
        flex-direction: row;
        box-shadow: none;
        transform: translateX(0);
      }

      & > a {
        text-decoration: none;
        color: $color-black;
        font-size: 0.75rem;
        padding: 0.75rem;
        cursor: pointer;

        &:not(:last-child) {
          border-bottom: 1px solid $color-dark-green;
        }

        @include respond-to(min-width, 414px) {
          font-size: 0.875rem;
        }

        @include respond-to(min-width, 768px) {
          padding: 0.75rem 0.5rem;
          font-size: 1rem;

          &:not(:last-child) {
            border-bottom: none;
          }

          &:not(:first-child) {
            margin-left: 0.25rem;
          }
        }

        @include respond-to(min-width, 1024px) {
          padding: 0.75rem;
        }

        &:hover {
          text-decoration: underline;
        }


      }
    }
  }
</style>

<script>
import {onMounted, ref} from "vue";

export default {
  name: "Navbar",
  setup(props, {emit}) {
    const scrollPositionY = ref(0);
    const hamburgerMenuStatus = ref(false);

    const scrollToFooter = () => {
      emit('scrollToFooter')
    }

    onMounted(() => {
      window.addEventListener('scroll', (event) => {
        scrollPositionY.value = event?.target?.scrollingElement?.scrollTop ?? 0;
      });
    });

    const openMenu = () => {
      hamburgerMenuStatus.value = !hamburgerMenuStatus.value;
    }

    return {scrollPositionY, hamburgerMenuStatus, scrollToFooter, openMenu};
  }
}
</script>