import {createRouter, createWebHistory} from "vue-router";
import AboutMe from "@/views/AboutMe.vue";
import MainPage from "@/views/MainPage.vue";
import Proctology from "@/views/Proctology.vue";
import Ultrasound from "@/views/Ultrasound.vue";
import PageNotFound from "@/views/PageNotFound.vue";
import WoundTreatment from "@/views/WoundTreatment.vue";
import Surgery from "@/views/Surgery.vue";

const routes = [
    {
        path: '/',
        name: 'MainPage',
        component: MainPage,
    },
    {
        path: '/about-me',
        name: 'AboutMe',
        component: AboutMe
    },
    {
        path: '/proctology',
        name: 'Proctology',
        component: Proctology
    },
    {
        path: '/ultrasound',
        name: 'Ultrasound',
        component: Ultrasound
    },
    {
        path: '/wound-treatment',
        name: 'WoundTreatment',
        component: WoundTreatment
    },
    {
        path: '/surgery',
        name: 'Surgery',
        component: Surgery
    },
    {
        path: '/:pathMatch(.*)',
        name: 'PageNotFound',
        component: PageNotFound
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        return {top: 0}
    }
});

export default router;