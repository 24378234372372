<template>
  <div class="ultrasoundWrapper">
    <div>
      <img src="../assets/icons/ultrasound.svg" width="200" height="194" alt="Ikona USG" />
      <h2>Ultrasonografia</h2>
    </div>

    <section class="diagnosisWrapper" :class="{'active': diagnosisStatus}">
      <button @click="toggleSection('diagnosis')">
        <img src="../assets/icons/arrow.svg" width="100" height="87" alt="Ikona strzałki" />
        <div>Diagnostyka chorób przy pomocy aparatu USG:</div>
      </button>

      <div>
        <ul>
          <li>
            <strong>Kanału odbytu wraz z zwieraczami (transrektalne 3D)</strong>
            <div>Os. dorosłe i dzieci powyżej 2 roku życia</div>
          </li>
          <li>
            <strong>Jamy brzusznej</strong>
            <div>Os. dorosłe i dzieci powyżej 2 roku życia</div>
          </li>
          <li>
            <strong>Szyi</strong>
            <div>Os. dorosłe i dzieci powyżej 2 roku życia</div>
          </li>
          <li>
            <strong>Tarczycy</strong>
            <div>Os. dorosłe i dzieci powyżej 2 roku życia</div>
          </li>
          <li>
            <strong>Piersi</strong>
          </li>
          <li>
            <strong>Doppler tętnic szyjnych i kręgowych (przepływy)</strong>
          </li>
        </ul>
      </div>

      <button class="iconWrapper" @click="toggleSection('diagnosis')">
        <img class="plusIcon" src="../assets/icons/big-plus.svg" width="233" height="233" alt="Ikona plusa" />
      </button>
    </section>

    <hr />

    <section class="preparationForUltrasoundWrapper" :class="{'active': preparationForUltrasoundStatus}">
      <button @click="toggleSection('preparationForUltrasound')">
        <img src="../assets/icons/arrow.svg" width="100" height="87" alt="Ikona strzałki" />
        <div>Jak przygotować się do USG jamy brzusznej?</div>
      </button>

      <div>
        <h4>Na badanie USG jamy brzusznej należy:</h4>

        <ul>
          <li>
            przyjść na badanie w luźniejszym stroju i nie uciskającej brzucha bieliźnie
          </li>
          <li>
            powstrzymać się od jedzenia pokarmów co najmniej 6 godzin przed badaniem, a jeżeli
            to możliwe to nawet 8 godzin.
          </li>
          <li>
            dzień przed badaniem oraz w dniu USG brzucha nie spożywać alkoholu, nie palić
            papierosów i e-papierosów, zrezygnować z kawy oraz gum do żucia – jest to bardzo
            istotny element przygotowania do USG jamy brzusznej
          </li>
          <li>
            by zmniejszyć ewentualne wzdęcia i pozbyć się gazów warto przyjąć Espumisan w dniu
            poprzedzającym badanie (3 x dziennie po 3 tabletki)
          </li>
          <li>
            warto również w dniu poprzedzającym badanie przyjmować tylko posiłki lekkostrawne,
            co oznacza rezygnację z dań smażonych, napojów gazowanych, roślin strączkowych,
            które wzdymają. Powinno się również zredukować spożycie błonnika, czyli nie jeść
            surowych warzyw i owoców oraz ciemnego pieczywa
          </li>
          <li>
            na 1-1,5 h przed badaniem należy wypić minimum litr wody niegazowanej, by podczas
            USG jamy brzusznej pęcherz był pełny – pozwoli to lepiej ocenić lekarzowi pęcherz
            moczowy , a w przypadku mężczyzn także gruczoł krokowy.
          </li>
        </ul>

        <strong>
          Brak przestrzegania zaleceń dotyczących przygotowania do USG jamy brzusznej może
          skutkować zafałszowanym wynikiem USG, co może być niebezpieczne dla zdrowia
          pacjenta.
        </strong>

        <h4>
          Zgłaszając się na badanie USG Pacjent powinien przynieść ze sobą:
        </h4>

        <ul>
          <li>wyniki wykonanych dotychczas badań USG</li>
          <li>wyniki innych badań dotyczących diagnozowanego problemu</li>
          <li>wypisy ze szpitala</li>
          <li>opisy przebytych operacji.</li>
        </ul>
      </div>

      <button class="iconWrapper" @click="toggleSection('preparationForUltrasound')">
        <img class="plusIcon" src="../assets/icons/big-plus.svg" width="233" height="233" alt="Ikona plusa" />
      </button>
    </section>

    <hr />

    <section class="priceListWrapper" :class="{'active': priceListSectionStatus}">
      <button @click="toggleSection('priceList')">
        <img src="../assets/icons/arrow.svg" width="100" height="87" alt="Ikona strzałki" />
        <div>Cennik</div>
      </button>

      <div>
        <h4 class="sectionTitle">Osoby dorosłe:</h4>
        <ul>
          <li>
            <span class="name">
              <strong>USG transrektalne 3D</strong>
            </span>
            <span>320zł</span>
          </li>
          <li>
            <span class="name">
              <strong>USG jamy brzusznej</strong>
            </span>
            <span>130zł</span>
          </li>
          <li>
            <span class="name">
              <strong>USG piersi</strong>
            </span>
            <span>130zł</span>
          </li>
          <li>
            <span class="name">
              <strong>USG tarczycy</strong>
            </span>
            <span>130zł</span>
          </li>
          <li>
            <span class="name">
              <strong>USG szyi</strong>
              <small>(węzły chłonne + ślinianki)</small>
            </span>
            <span>130zł</span>
          </li>
          <li>
            <span class="name">
              <strong>USG tętnic szyjnych</strong>
              <small>(przepływy)</small>
            </span>
            <span>130zł</span>
          </li>
        </ul>

        <h4 class="sectionTitle">Dzieci od 2 roku życia:</h4>

        <ul>
          <li>
            <span class="name">
              <strong>USG jamy brzusznej</strong>
            </span>
            <span>130zł</span>
          </li>
          <li>
            <span class="name">
              <strong>USG tarczycy</strong>
            </span>
            <span>130zł</span>
          </li>
          <li>
            <span class="name">
              <strong>USG szyi</strong>
              <small>(węzły chłonne + ślinianki)</small>
            </span>
            <span>130zł</span>
          </li>
          <li>
            <span class="name">
              <strong>USG jamy brzusznej</strong>
            </span>
            <span>130zł</span>
          </li>
        </ul>

        <div class="additionalInformation">Możliwość płatności kartą</div>
      </div>

      <button class="iconWrapper" @click="toggleSection('priceList')">
        <img class="plusIcon" src="../assets/icons/big-plus.svg" width="233" height="233" alt="Ikona plusa" />
      </button>
    </section>
  </div>
</template>

<style scoped lang="scss">
  @import '../assets/styles/colors';
  @import '../assets/styles/reusable';
  @import '../assets/styles/mixins';

  div.ultrasoundWrapper {
    @extend %diagnostics;

    & > section {
      &.diagnosisWrapper {
        & > div {
          & > ul {
            list-style-type: none;
            padding-left: 1rem;
            margin-bottom: 0;

            @include respond-to(min-width, 768px) {
              padding-left: 2rem;
              margin-bottom: 1rem;
            }

            & > li {
              margin: 1.5rem 0;

              &:first-child {
                margin-top: 0;
              }

              &:last-child {
                margin-bottom: 0;
              }

              & > strong {
                display: block;
                margin-bottom: 0.25rem;
              }
            }
          }
        }
      }

      &.preparationForUltrasoundWrapper {
        & > div {
          padding: 0 1rem;

          @include respond-to(min-width, 768px) {
            padding: 0 2rem;
          }

          & > h4 {
            font-size: 1.25rem;
          }

          & > ul {
            margin-top: 0;
          }

          & > strong {
            display: block;
            margin: 1rem 0;
          }
        }
      }
    }
  }
</style>

<script>
import {ref} from "vue";

export default {
  name: "Ultrasound.vue",
  setup() {
    const diagnosisStatus = ref(true);
    const preparationForUltrasoundStatus = ref(false);
    const priceListSectionStatus = ref(false);
    const toggleSection = (section) => {
      switch(section) {
        case 'diagnosis':
          diagnosisStatus.value = !diagnosisStatus.value;
          break;
        case 'preparationForUltrasound':
          preparationForUltrasoundStatus.value = !preparationForUltrasoundStatus.value;
          break;
        case 'priceList':
          priceListSectionStatus.value = !priceListSectionStatus.value;
          break;
      }
    }

    return {diagnosisStatus, preparationForUltrasoundStatus, priceListSectionStatus, toggleSection}
  }
}
</script>