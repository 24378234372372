<template>
  <div class="woundTreatmentWrapper">
    <div>
      <img src="../assets/icons/surgery.svg" width="200" height="200" alt="Ikona leczenia ran" />
      <h2>Chirurgia</h2>
    </div>

    <section class="diagnosisWrapper" :class="{'active': diagnosisStatus}">
      <button @click="toggleSection('diagnosis')">
        <img src="../assets/icons/arrow.svg" width="100" height="87" alt="Ikona strzałki" />
        <div>Kwalifikacja do zabiegów operacyjnych w szpitali specjalistycznym w Mielcu:</div>
      </button>
      <div>
        <ul>
          <li>
            <span>kamicy pęcherzyka żółciowego</span>
          </li>
          <li>
            <span>przepukliny pachwinowej, pępkowej, kresy białej</span>
          </li>
          <li>
            <span>znamion, kaszaków, tłuszczaków</span>
          </li>
          <li>
            <span>przewlekłych szczelin odbytu</span>
          </li>
          <li>
            <span>guzków krwawniczych (hemoroidów)</span>
          </li>
          <li>
            <span>torbieli pilonidalnych (krzyżowych)</span>
          </li>
          <li>
            <span>przerośniętych fałdów anodermy</span>
          </li>
          <li>
            <span>prostych przetok odbytu</span>
          </li>
        </ul>

      </div>

      <button class="iconWrapper" @click="toggleSection('diagnosis')">
        <img class="plusIcon" src="../assets/icons/big-plus.svg" width="233" height="233" alt="Ikona plusa" />
      </button>
    </section>

    <hr />


    <section class="priceListWrapper" :class="{'active': priceListSectionStatus}">
      <button @click="toggleSection('priceList')">
        <img src="../assets/icons/arrow.svg" width="100" height="87" alt="Ikona strzałki" />
        <div>Cennik</div>
      </button>

      <div>
        <ul>
          <li>
            <span class="name">
              <strong>Konsultacja chirurgiczna</strong>
            </span>
            <span>150zł</span>
          </li>
        </ul>
        <div class="additionalInformation">Możliwość płatności kartą</div>

      </div>

      <button class="iconWrapper" @click="toggleSection('priceList')">
        <img class="plusIcon" src="../assets/icons/big-plus.svg" width="233" height="233" alt="Ikona plusa" />
      </button>
    </section>
  </div>
</template>

<style scoped lang="scss">
@import '../assets/styles/colors';
@import '../assets/styles/reusable';

div.woundTreatmentWrapper {
  @extend %diagnostics;

  & > section {
    &.diagnosisWrapper {
      & > div {
        & > ul {
          padding-left: 1rem;

          @include respond-to(min-width, 768px) {
            padding-left: 2.5rem;
          }

          & > li {
            margin: 1.5rem 0;

            &:first-child {
              margin-top: 0;
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    &.homeVisitsWrapper {
      & > div {
        padding-left: 1rem;

        @include respond-to(min-width, 768px) {
          padding-left: 2.5rem;
        }
      }
    }
  }
}
</style>

<script>
import {ref} from "vue";

export default {
  name: "Surgery.vue",
  setup() {
    const diagnosisStatus = ref(true);
    const preparationForUltrasoundStatus = ref(false);
    const priceListSectionStatus = ref(false);
    const toggleSection = (section) => {
      switch(section) {
        case 'diagnosis':
          diagnosisStatus.value = !diagnosisStatus.value;
          break;
        case 'preparationForUltrasound':
          preparationForUltrasoundStatus.value = !preparationForUltrasoundStatus.value;
          break;
        case 'priceList':
          priceListSectionStatus.value = !priceListSectionStatus.value;
          break;
      }
    }

    return {diagnosisStatus, preparationForUltrasoundStatus, priceListSectionStatus, toggleSection}
  }
}
</script>