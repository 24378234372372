<template>
    <Home></Home>
    <Introduction></Introduction>
    <Diagnostics></Diagnostics>
</template>

<script>
import Home from "@/components/Home";
import Introduction from "@/components/Introduction";
import Diagnostics from "@/components/Diagnostics";

export default {
  name: 'MainPage',
  components: {
    Home,
    Introduction,
    Diagnostics
  }
}
</script>

<style scoped lang="scss">

</style>