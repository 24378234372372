<template>
  <div class="aboutMeWrapper">
    <img src="../assets/images/skala-home.webp" width="1080" height="1440" alt="Zdjęcie lek. Piotr Bereś" />
    <div class="textWrapper">
      <p>
        Szanowni Państwo,
      </p>

      <p>
        Zajmuję się leczeniem chorób z zakresu chirurgii koloproktogicznej oraz leczeniem ran przewlekłych i trudno gojących się.
        Wykonuję także badania ultrasonograficzne.
      </p>

      <p>
        Oprócz ukończenia studiów na kierunku lekarskim, jestem absolwentem studiów podyplomowych Collegium Medicum Uniwersytetu
        Jagielońskiego o profilu Koloproktologia praktyczna, zdobywając najnowszą wiedzę na temat diagnostyki i leczenia schorzeń jelita grubego oraz odbytu.
        Odbyłem liczne kursy w Roztoczańskiej Szkole Ultrasonografii, ucząc się oraz doszkalając technikę badania USG.
      </p>

      <p>
        W trosce o jak najlepsze rezultaty leczenia moich Pacjentów, na bieżąco poszerzam swoją wiedzę na licznych kursach doszkalających oraz sympozjach.
        Regularnie uczestniczę w corocznych konferencjach naukowych krajowych i zagranicznych, a także warsztatach proktologicznych oraz chirurgicznych.
      </p>
      <p>
        W swojej codziennej praktyce zawodowej  zawsze staram się  mieć czas na kilka słów z każdym Pacjentem.
        Zwykłą rozmową możemy zrobić dużo dobrego. Podchodzę  do chorego  całościowo, z uwzględnieniem nie tylko choroby podstawowej,
        ale także innych dolegliwości, a przede wszystkim – ze zrozumieniem aspektów Pacjenta jako osoby.
      </p>

      <p>Serdecznie zachęcam do kontaktu.</p>
      <p>lek. Piotr Bereś</p>
    </div>

    <div class="certificates">
      <h3>Certyfikaty i dyplomy</h3>

      <button class="galleryArrow leftArrow" @click="scrollGallery(-1)">
        <span></span>
      </button>

      <div ref="certificates" class="certificatesWrapper">
        <button v-for="(image, index) in certificatesImages" :key="index + '_' + image.name" @click="openLightBox(image)" title="Naciśnij aby powiększyć">
          <div :style="'--image-name: url(' + getImagePath(image.name, image.extension) + ');'" @contextmenu.prevent></div>
        </button>
      </div>

      <button class="galleryArrow rightArrow" @click="scrollGallery(1)">
        <span></span>
      </button>
    </div>

    <div class="iconWrapper">
      <img src="../assets/icons/plus.svg" width="100" height="100" alt="Ikona plus" />
    </div>

    <LightBox v-if="lightBoxStatus" :image="selectedLightboxImage" @closeLightBox="closeLightBox"></LightBox>
  </div>
</template>

<style scoped lang="scss">
  @import '../assets/styles/colors';
  @import '../assets/styles/mixins';

  div.aboutMeWrapper {
    background-color: $color-light-green;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include respond-to(min-width, 568px) {
      display: block;
    }

    @include respond-to(min-width, 768px) {
      padding: 3rem 3rem 1rem 3rem;
    }

    @include respond-to(min-width, 1024px) {
      padding: 3rem 5rem 1rem 5rem;
    }

    & > img {
      --image-height: 300px;

      object-fit: contain;
      width: 100%;
      max-width: 350px;
      height: auto;
      margin-bottom: 2rem;

      @include respond-to(min-width, 568px) {
        width: auto;
        max-height: var(--image-height);
        margin-right: 1rem;
        margin-bottom: 1rem;
        float: left;
      }

      @include respond-to(min-width, 768px) {
        --image-height: 400px;
      }
    }

    & > div.textWrapper {
      color: $color-white;
      text-align: justify;

      & > p {
        font-size: 1.25rem;

        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          text-align: right;
        }
      }
    }

    & > div.certificates {
      $arrowSize: 50px;

      display: grid;
      grid-template-areas: "header"
                           "certificates";
      grid-template-columns: 1fr;
      grid-gap: 0.5rem 1rem;
      margin-bottom: 3rem;

      @include respond-to(min-width, 768px) {
        grid-template-areas: "header header header"
                             "leftArrow certificates rightArrow";
        grid-template-columns: 50px 1fr 50px;
      }

      & > h3 {
        grid-area: header;
        font-size: 1.5rem;
        color: $color-white;
      }

      & > button.galleryArrow {
        align-self: center;
        height: $arrowSize * sqrt(2);
        overflow: hidden;
        display: none;
        background-color: transparent;
        border: none;
        cursor: pointer;

        @include respond-to(min-width, 768px) {
          display: block;
        }

        &.leftArrow {
          grid-area: leftArrow;

          & > span {
            transform: translate(40%, 0%) rotate(45deg);
          }
        }

        &.rightArrow {
          grid-area: rightArrow;

          & > span {
            transform: translate(-60%, 0%) rotate(45deg);
          }
        }

        & > span {
          display: block;
          width: $arrowSize;
          height: $arrowSize;
          background-color: $color-dark-green;
          pointer-events: none;
        }
      }

      & > div.certificatesWrapper {
        overflow: auto;
        display: flex;
        grid-area: certificates;

        & > button {
          cursor: pointer;

          &:not(:first-child) {
            margin-left: 2rem;
          }

          & > div {
            width: 300px;
            height: 210px;
            $imageName: #{"var(--image-name)"};
            background-image: var(--image-name);
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
          }

          & > img {
            width: auto;

            object-fit: contain;
          }
        }
      }
    }

    & > div.iconWrapper {
      display: flex;
      justify-content: center;

      & > img {
        object-fit: contain;
        width: fit-content;
        height: 100px;
      }
    }
  }
</style>

<script>
import {ref} from "vue";
import LightBox from "@/components/LightBox.vue";

export default {
  name: 'AboutMe',
  components: {LightBox},
  setup() {
    const lightBoxStatus = ref(false);
    const selectedLightboxImage = ref(null);
    const certificates = ref(null);
    const certificatesImages = ref([
      {
        name: '1ab5d6690ceaf84dcd058b795ac98a29bc0108d4e420d3b390eb28b1c87f65c3',
        extension: 'webp',
      },
      {
        name: 'bd0fc77f688bbe2fdeb808af2cadbc86a39c99dde6314b677e7fb478788dd91f',
        extension: 'webp',
      },
      {
        name: '67414dfe9cc19c1108bcdf75b7104319be8ccacd7c7a65ba548d1971bafbb9fc',
        extension: 'webp',
      },
      {
        name: '4a5d9eba1221a75fbd4a1d84e85fae80c5d30e1462b2431db454e89ed503ce81',
        extension: 'webp',
      },
      {
        name: '7c602c51484b5a6f19e82fd8925b1be5e246b69080bf8357429baeb914333b3a',
        extension: 'webp',
      },
      {
        name: '41c8b9650c4c8c2e530712567c0e6ec9c3d0ee0565612a004c1eeaebcb3df8ad',
        extension: 'webp',
      },
      {
        name: '39ba148e846530f6566a90dafa5f31bb381976e30f4c7f45fdee4bf8c07e8342',
        extension: 'webp',
      },
      {
        name: '27fa08735eacf5d83eae988199d47ffe3defdfd6629db43ff7f9d215e961fa9f',
        extension: 'webp',
      },
      {
        name: '267c7613ee0aca50a59fe690214d70372853a6bd0bfafbe67f9d065cb91f559c',
        extension: 'webp',
      },
      {
        name: 'd426a86f1acf85d38e0aafdf5a2e6e6f9f777da0160d7a4487cb5feec1535640',
        extension: 'webp',
      },
      {
        name: 'dbda345983c3a660ef5ab2ac23b9e160c28b1d3fba820a0bc097c5a23e9843fd',
        extension: 'webp',
      },
      {
        name: 'c3d1c4821274be785b35ed29de5aa4a3adf18ef155d043755ef7421004b5eb57',
        extension: 'webp'
      }
    ]);

    const getImagePath = (name, extension) => {
      return require('@/assets/images/certificates/mini/' + name + '.' + extension);
    }

    const openLightBox = (image) => {
      lightBoxStatus.value = true;
      selectedLightboxImage.value = image;
    }

    const closeLightBox = () => {
      lightBoxStatus.value = false;
    }

    const scrollGallery = (way) => {
      let offset = 400;

      if (way < 0) {
        offset = offset * -1;
      }

      if (certificates.value) {
        certificates.value.scrollBy({
          top: 0,
          left: offset,
          behavior: 'smooth'
        });
      }
    }

    return {certificatesImages, lightBoxStatus, selectedLightboxImage, certificates, getImagePath, openLightBox, closeLightBox, scrollGallery}
  }
}
</script>
