<script lang="ts" setup>

</script>

<template>
  <div class="homeWrapper">
    <div class="textWrapper">
      <img src="../assets/icons/plus.svg" width="100" height="100" alt="Ikona plusa" />
      <h2>Prywatny gabinet proktologiczno-chirurgiczny i USG</h2>
      <div>
        <p>lek. Piotr Bereś</p>
        <p>ELMED Nagawczyna</p>
        <a href="https://maps.app.goo.gl/hG4rR2c4DqQHt7yV6" target="_blank">Nagawczyna 24A</a>
      </div>
    </div>
    <div class="imagesWrapper">
      <div class="emptyPlaceholder"></div>
      <img src="../assets/images/clinic-home-mini.webp" class="clinicImg" width="300" height="400" alt="Zdjęcie przychodni" />
      <img src="../assets/images/skala-home-mini.webp" class="doctorImg" width="300" height="400" alt="Zdjęcie lek. Piotr Bereś" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @import '../assets/styles/colors';
  @import '../assets/styles/mixins';

  div.homeWrapper {
    background-color: $color-light-green;
    display: grid;
    grid-template-columns: 1fr;
    padding: 1rem 0.5rem;
    min-height: 550px;

    @include respond-to(min-width, 568px) {
      padding: 1rem 2rem;
    }

    @include respond-to(min-width, 1024px) {
      grid-template-columns: 50% 50%;
    }

    @include respond-to(min-width, 1366px) {
      min-height: 700px;
    }

    & > div.textWrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      @include respond-to(min-width, 1024plus) {
        padding: 2rem 3rem;
      }

      & > img {
        max-width: 60px;
        max-height: 60px;
        object-fit: contain;

        @include respond-to(min-width, 568px) {
          max-width: 80px;
          max-height: 80px;
        }
      }

      & > h2 {
        font-size: 2.5rem;
        font-weight: normal;
        color: $color-smoke;
        text-align: center;

        @include respond-to(min-width, 568px) {
          font-size: 4rem;
          text-align: left;
        }

        @include respond-to(min-width, 1366px) {
          font-size: 5.5rem;
        }
      }

      & > div {
        text-align: center;

        @include respond-to(min-width, 568px) {
          text-align: left;
        }

        & > p,
        & > a {
          font-size: 1rem;
          margin: 0.25rem;

          @include respond-to(min-width, 568px) {
            font-size: 1.5rem;
          }
        }

        & > a {
          text-decoration: none;
          color: $color-black;

          &:hover {
            text-decoration: underline;
            text-decoration-thickness: 2px;
          }
        }
      }
    }

    & > div.imagesWrapper {
      --horizontal-offset: 80px;
      --top-offset: 100px;

      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      margin: 2rem 0 1rem 0;

      @include respond-to(min-width, 568px) {
        flex-direction: row-reverse;
        justify-content: space-around;
        margin: 3rem 0 2rem 0;
      }

      @include respond-to(min-width, 1024px) {
        position: relative;
        margin: 0;
      }

      @include respond-to(min-width, 1366px) {
        --horizontal-offset: 110px
      }

      & > div.emptyPlaceholder,
      & > img {
        --image-width: 195px;
        --image-height: 260px;
        width: var(--image-width);
        height: var(--image-height);

        @include respond-to(min-width, 568px) {
          --image-width: 225px;
          --image-height: 300px;
        }

        @include respond-to(min-width, 1024px) {
          position: absolute;
          top: 50%;
          right: calc(0px + (var(--horizontal-offset)));
          transform: translate(0, -50%);
        }

        @include respond-to(min-width, 1366px) {
          --image-width: 300px;
          --image-height: 400px;
        }
      }

      & > img {
        object-fit: cover;
        //width: 100%;
        max-width: 100%;
        max-height: 768px;
      }

      & > div.emptyPlaceholder {
        background-color: $color-dark-green;
        display: none;

        @include respond-to(min-width,1024px) {
          display: block;
        }
      }

      & > img.doctorImg {
        top: calc(50% + var(--top-offset));
        right: calc(var(--horizontal-offset) + var(--horizontal-offset));
      }

      & > img.clinicImg {
        top: calc(50% - var(--top-offset));
        right: calc(var(--horizontal-offset) - var(--horizontal-offset));
        margin-top: 1rem;

        @include respond-to(min-width, 568px) {
          margin-top: unset;
        }
      }
    }
  }
</style>