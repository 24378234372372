<template>
  <div class="lightBoxOverlay">
    <button class="closeBtn" @click="closeLightBox">
      <img src="../assets/icons/big-plus.svg" width="100" height="100" alt="Ikona zamknięcia" />
    </button>
    <div class="lightBoxWrapper">
      <div :style="'--image-name: url(' + getImagePath(selectedImage.name, selectedImage.extension) + ');'" @contextmenu.prevent></div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '../assets/styles/colors';

  div.lightBoxOverlay {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba($color-black, 0.5);
    z-index: 100;
    backdrop-filter: blur(5px);

    & > button.closeBtn {
      position: absolute;
      right: 10px;
      top: 5px;
      background-color: transparent;
      border: none;
      cursor: pointer;

      & > img {
        max-width: 40px;
        max-height: 40px;
        object-fit: contain;
        transform: rotate(45deg);
      }
    }

    & > div.lightBoxWrapper {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      & > div {
        width: 100%;
        height: 100%;
        max-width: 90vw;
        max-height: 90vh;
        $imageName: #{"var(--image-name)"};
        background-image: var(--image-name);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }

      & > img {
        width: auto;
        max-width: 90%;
        max-height: 90%;
        object-fit: contain;
      }
    }
  }
</style>

<script>
import {onMounted, ref} from "vue";

export default {
  name: 'LightBox',
  props: ['image'],
  setup(props, {emit}) {
    const selectedImage = ref(props.image);

    onMounted(() => {
      console.log('props', props.image, selectedImage.value);
    });

    const getImagePath = (name, extension) => {
      return require('@/assets/images/certificates/' + name + '.' + extension);
    }

    const closeLightBox = () => {
      emit('closeLightBox');
    }

    return {selectedImage, getImagePath, closeLightBox}
  }
}

</script>